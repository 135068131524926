<template>
  <Slider
    v-if="products"
    :headline="headline"
    :headline-level="headlineLevel"
    :items="products"
    :column-data="columnData"
    slider-type="product"
  />
</template>

<script setup lang="ts">
  import { deserialize } from "@alchemy_cms/json_api"

  const props = defineProps<{
    element: AlchemyElement
  }>()

  const { getIngredient, getValue } = useAlchemy()
  const { api } = useApi()

  const { data: products } = await useAsyncData<SolidusProduct[]>(
    `${props.element.name}-${props.element.id}`,
    () => api("/jsonapi/top_selling_fragrances"),
    { transform: deserialize },
  )

  const attrs = useAttrs()

  let columnData: {
    columnLayout?: any
    columnPlacement?: any
  } = {}

  if (attrs["data-column"] === "true") {
    columnData = {
      columnLayout: attrs["data-column-layout"],
      columnPlacement: attrs["data-column-placement"],
    }
  }

  const headline = getValue<string>(props.element, "headline")
  const headlineLevel =
    getIngredient<AlchemyIngredientHeadline>(props.element, "headline")
      ?.level || 3
</script>
